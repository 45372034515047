import React, { Component } from "react";
import { config } from "./constants";
import { Elements } from "./elements";

export default class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      elements: null,
    };
  }
  componentDidMount() {
    fetch(
      config.url.SITE_URL + "api.php?search=" + this.props.match.params.search
    )
      .then((response) => response.json())
      .then((data) => this.setState({ elements: data }));
  }
  render() {
    return (
      <div className="page">
        {this.state.elements && (
          <Elements elements={this.state.elements}></Elements>
        )}
        {!this.state.elements && (
          <div className="pageHeadLine">
            <div>nothing was found for the searched phrase</div>
          </div>
        )}
        <div className="clear"></div>
      </div>
    );
  }
}
