import React from "react";
import "./App.css";

import { BrowserRouter as Router, Route } from "react-router-dom";

import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Home from "./components/home.js";
import Current from "./components/current.js";
import CurrentOne from "./components/currentOne.js";
import Past from "./components/past.js";
import PastOne from "./components/pastOne.js";
import Upcoming from "./components/upcoming.js";
import UpcomingOne from "./components/upcomingOne.js";
import About from "./components/about.js";
import Interviews from "./components/interviews.js";
import Interview from "./components/interview.js";
import Support from "./components/support.js";
import Search from "./components/search.js";

import { config } from "./components/constants";

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Router basename={config.url.LINK_PREFIX}>
        <div className="container">
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/projects" component={Current} />
          <Route exact path="/projects/:elementId" component={CurrentOne} />
          <Route exact path="/past" component={Past} />
          <Route exact path="/past/:elementId" component={PastOne} />
          <Route exact path="/events" component={Upcoming} />
          <Route exact path="/events/:elementId" component={UpcomingOne} />
          <Route exact path="/interviews" component={Interviews} />
          <Route exact path="/interviews/:elementId" component={Interview} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/search/:search" component={Search} />
        </div>
      </Router>
      <Footer></Footer>
    </div>
  );
}

export default App;
