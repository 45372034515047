const prod = {
  url: {
    SITE_URL: "/",
    LINK_PREFIX: "/",
  },
};
const dev = {
  url: {
    SITE_URL: "http://makeartwithpurpose.net/",
    LINK_PREFIX: "/",
  },
};
export const config = process.env.NODE_ENV === "development" ? dev : prod;
