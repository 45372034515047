import React, { Component } from "react";
import logo from "../MAP_logo.svg";
import { config } from "./constants";

class Link extends React.Component {
  render() {
    if (this.props.value==='events/news') return (
      <a className="events" href={config.url.LINK_PREFIX + "events"}>
        {this.props.value.split("_")[0]}
      </a>
    )
    else return (
      <a className={this.props.value.split("_")[0]} href={config.url.LINK_PREFIX + this.props.value.split(" ")[0]}>
        {this.props.value.split("_")[0]}
      </a>
    );
  }
}

class Search extends React.Component {
  _handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      window.location.href =
        config.url.LINK_PREFIX + "search/" + encodeURIComponent(e.target.value);
    }
  };
  render() {
    return (
      <span>
        <input
          type="text"
          placeholder="search MAP"
          onKeyDown={this._handleKeyDown}
        ></input>
        <i className="fa fa-search" aria-hidden="true"></i>
      </span>
    );
  }
}

class Menu extends React.Component {
  renderLink(page) {
    return <Link value={page} />;
  }
  renderProjectLink() {
    return (
      <div className="sub">
        <div className="openSubMenu">Projects</div>
        <div className="subMenu">
          <Link value="current / on-going" /> <br />
          <Link value="past" />
        </div>
      </div>
    );
  }
  renderEventLink() {
    return (
      <div className="sub">
        <div className="openSubMenu">Events/News</div>
        <div className="subMenu">
          <Link value="upcoming" /> <br />
          <Link value="past_events" />
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="menu">
        {this.renderLink("projects")}
        {this.renderLink("events/news")}
        {this.renderLink("interviews")}
        {this.renderLink("about")}
        {this.renderLink("support")}
        <Search></Search>
      </div>
    );
  }
}

export default class Header extends Component {
  render() {
    return (
      <div className="header">
        <a href="/">
          <img src={logo} className="logo" alt="logo" />
        </a>
        <Menu></Menu>
        <div className='clear'></div>
      </div>
    );
  }
}
