import React, { Component } from "react";
import { config } from "./constants";

export default class CurrentOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      element: {
        var0: "",
        var1: "",
        var2: "",
        var3: "",
        var4: "",
        var5: "",
        var6: "",
      },
    };
  }
  componentDidMount() {
    fetch(
      config.url.SITE_URL + "api.php?id=" + this.props.match.params.elementId
    )
      .then((response) => response.json())
      .then((data) => this.setState({ element: data }));
  }
  render() {
    return (
      <div className="page">
        <div className="pageHeadLine">
          <div>Projects</div>
          <div className="clear"></div>
        </div>
        <div className="singleHeader">
          <h1 dangerouslySetInnerHTML={{ __html: this.state.element["var0"] }} />
          <div>{this.state.element["var2"]}</div>
          <div>
            {this.state.element["var3"]}
          </div>
        </div>
        <div className="singleImage">
          <img
            src={config.url.SITE_URL + "files/" + this.state.element["var5"]}
            alt={this.state.element["var0"]}
          />
        </div>
        <div className="clear"></div>
        <div
          className="aboutContent"
          dangerouslySetInnerHTML={{ __html: this.state.element["var6"] }}
        ></div>
      </div>
    );
  }
}
