import React, { Component } from "react";
import { config } from "./constants";

class People extends React.Component {
  renderPeople() {
    if (this.props.people)
      return this.props.people.map((item) => {
        return (
          <div key={item["key"]}>
            <div>
              <img
                src={config.url.SITE_URL + "files/m_" + item["var2"]}
                alt={item["var0"]}
                className="personImage"
              />
            </div>
            <div>
              <strong>{item["var0"]}</strong>
            </div>
            <div>{item["var1"]}</div>
            <p dangerouslySetInnerHTML={{ __html: item["var3"] }} />
          </div>
        );
      });
  }
  render() {
    return <div className="aboutContent">{this.renderPeople()}</div>;
  }
}

class NameList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      all: false,
    };
  }
  expand = () => {
    if (this.state.all) this.setState({ all: false });
    else this.setState({ all: true });
  };
  renderPartners() {
    var to_return = "";
    if (this.props.names) {
      for (var i = 0; i < this.props.names.length; i++) {
        to_return += this.props.names[i].var0 + "<br>";
        if (this.state.all === false && i > 5) break;
      }
    }
    return to_return;
  }
  render() {
    let button;
    if (this.state.all) {
      button = <div onClick={this.expand}></div>;
    } else {
      button = (
        <div onClick={this.expand} className="more">
          <i class="fa fa-bars" aria-hidden="true"></i> more...
        </div>
      );
    }

    return (
      <div className="aboutContent">
        <div dangerouslySetInnerHTML={{ __html: this.renderPartners() }} />
        {button}
      </div>
    );
  }
}

export default class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mission: null,
      vision: null,
      advisory: null,
      people: null,
      partners: null,
      supporters: null,
      directors: null,
      about: null,
    };
  }
  componentDidMount() {
    fetch(config.url.SITE_URL + "api.php?id=10")
      .then((response) => response.json())
      .then((data) => this.setState({ mission: data.var1 }));

    fetch(config.url.SITE_URL + "api.php?id=66")
      .then((response) => response.json())
      .then((data) => this.setState({ vision: data.var1 }));

    fetch(config.url.SITE_URL + "api.php?id=11")
      .then((response) => response.json())
      .then((data) => this.setState({ advisory: data.var1 }));

    fetch(config.url.SITE_URL + "api.php?id=60")
      .then((response) => response.json())
      .then((data) => this.setState({ about: data.var1 }));

    fetch(config.url.SITE_URL + "api.php?id=61")
      .then((response) => response.json())
      .then((data) => this.setState({ directors: data.var1 }));

    fetch(config.url.SITE_URL + "api.php?ojciec=9&nazwa=person")
      .then((response) => response.json())
      .then((data) => this.setState({ people: data }));

    fetch(config.url.SITE_URL + "api.php?ojciec=13&nazwa=partner")
      .then((response) => response.json())
      .then((data) => this.setState({ partners: data }));

    fetch(config.url.SITE_URL + "api.php?ojciec=13&nazwa=supporter")
      .then((response) => response.json())
      .then((data) => this.setState({ supporters: data }));
  }

  render() {
    return (
      <div className="page">
        <div className="pageHeadLine">
          <div>About</div>
          <div className="clear"></div>
        </div>
        <h2>Mission</h2>
        <div
          className="aboutContent"
          dangerouslySetInnerHTML={{ __html: this.state.mission }}
        />
        <h2>Vision</h2>
        <div
          className="aboutContent"
          dangerouslySetInnerHTML={{ __html: this.state.vision }}
        />
        <h2>About</h2>
        <div
          className="aboutContent"
          dangerouslySetInnerHTML={{ __html: this.state.about }}
        />
        <h2>Team</h2>
        <People people={this.state.people}></People>
        <h2>Board of Directors</h2>
        <div
          className="aboutContentDirectors"
          dangerouslySetInnerHTML={{ __html: this.state.directors }}
        />
        <h2>Advisory board</h2>
        <div
          className="aboutContent"
          dangerouslySetInnerHTML={{ __html: this.state.advisory }}
        />
        <br/>
        <div className="aboutContent">
          <strong>
          MAP projects and programs are produced<br /> with generous support from:
          </strong>
        </div>
        <NameList names={this.state.supporters}></NameList>
        <br/>
        <div className="aboutContent">
          <strong>MAP collaborating partners include:</strong>
        </div>
        <NameList names={this.state.partners}></NameList>
      </div>
    );
  }
}
