import React, { Component } from "react";
import { config } from "./constants";

export default class Support extends Component {
  constructor(props) {
    super(props);

    this.state = {
      support: null,
    };
  }
  componentDidMount() {
    fetch(config.url.SITE_URL + "api.php?id=40")
      .then((response) => response.json())
      .then((data) => this.setState({ support: data.var1 }));
  }

  render() {
    return (
      <div className="page">
        <div className="pageHeadLine">
          <div>Support</div>
          <div className="clear"></div>
        </div>
        <div
          className="aboutContent"
          dangerouslySetInnerHTML={{ __html: this.state.support }}
        />
      </div>
    );
  }
}
