import React, { useState } from "react";
import { config } from "./constants";

const Footer = () => {
  const [email, setEmail] = useState(null)

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const onNewMail = () => {
    if (!validateEmail(email)) {
      alert("Email is not valid")
      return null
    }

    fetch(
      config.url.SITE_URL + "newsletter.php",
      {
        method: 'post',
        body: JSON.stringify({ 
          title: "subscribe to newsletter",
          email: email
        })
      }
    )
    .then((response) => response.text())
    .then((data) => {
      if(data==='added') alert("Your email has been sent. Thank You")
      else alert('There was an error. Sorry')
    })
  }

  return (
    <div className="footer">
      <div className="footer_content">
        <div>© Make Art with Purpose { new Date().getFullYear() }</div>
        <div>
          <h3>Follow us on:</h3>
          <a href="https://www.facebook.com/pages/Make-Art-with-Purpose/211579438899592?sk=wall">
            Facebook
          </a>
          <br />
          <a href="https://twitter.com/#!/makeartwpurpose">Twitter</a>
          <br />
          <a href="https://instagram.com/makeartwithpurpose">Instagram</a>
          <br />
        </div>
        <div>
          <h3>Contact Us:</h3>
          info[at]makeartwithpurpose.net<br />
          <br />
          <h3>SUBSCRIBE TO MAP’S NEWSLETTER:</h3>
          <input
            placeholder='e-mail'
            value={ email }
            onChange={ e => setEmail(e.target.value) }
          />
          <span className='newsletter-submit' onClick={ () => onNewMail() }>
            submit
          </span>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  );
}

export default Footer