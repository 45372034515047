import React, { Component } from "react";
import { config } from "./constants";
import { Elements } from "./elements";

import Mapa_1 from "../Mapa_1.svg";
import Mapa_2 from "../Mapa_2.svg";
import Mapa_3 from "../Mapa_3.svg";
import Mapa_4 from "../Mapa_4.svg";
import Mapa_5 from "../Mapa_5.svg";

class ToolTip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: props.text,
      style: {
        top: props.top,
        left: props.left,
      },
    };
  }
  componentDidMount() {
    document.addEventListener("mousemove", this.onMouseMove);
  }
  componentWillUnmount() {
    document.removeEventListener("mousemove", this.onMouseMove);
  }
  onMouseMove = (e) => {
    let mapa = document.getElementsByClassName("map");
    let offsetTop = e.pageY - mapa[0].offsetTop;
    let offsetLeft = e.pageX - mapa[0].offsetLeft;
    this.setState({ style: { top: offsetTop, left: offsetLeft } });
  };
  render() {
    return (
      <div className="toolTip" style={this.state.style} dangerouslySetInnerHTML={{ __html: this.state.text }}/>
    );
  }
}

class Continent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: props.image,
      href: props.href,
      className: props.className,
      info: props.info,
      toolTip: false,
    };
  }
  mouseIn = () => {
    if (!this.state.toolTip) this.setState({ toolTip: true });
  };
  mouseOut = () => {
    if (this.state.toolTip) this.setState({ toolTip: false });
  };
  render() {
    return (
      <>
        {
          this.state.href &&
          <a href={this.state.href}>
            <img
              src={this.state.image}
              alt=""
              className={this.state.className}
              onMouseOver={this.mouseIn}
              onMouseOut={this.mouseOut}
            />
            {this.state.toolTip && <ToolTip text={this.state.info} />}
          </a>
        }
        {
          !this.state.href &&
          <>
            <img
              src={this.state.image}
              alt=""
              className={this.state.className}
              onMouseOver={this.mouseIn}
              onMouseOut={this.mouseOut}
            />
            {this.state.toolTip && <ToolTip text={this.state.info} />}
          </>
        }
      </>
    );
  }
}

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      elements: null,
    };
  }
  componentDidMount() {
    fetch(config.url.SITE_URL + "api.php?map_info=1")
      .then((response) => response.json())
      .then((data) => this.setState({ elements: data }));
  }
  render() {
    if (!this.state.elements) return null;
    return (
      <div className="map">
        <Continent image={Mapa_1} className="Mapa_1" info={this.state.elements[0]['info']} href={this.state.elements[0]['link']}></Continent>
        <Continent image={Mapa_2} className="Mapa_2" info={this.state.elements[1]['info']} href={this.state.elements[1]['link']}></Continent>
        <Continent image={Mapa_3} className="Mapa_3" info={this.state.elements[2]['info']} href={this.state.elements[2]['link']}></Continent>
        <Continent image={Mapa_4} className="Mapa_4" info={this.state.elements[3]['info']} href={this.state.elements[3]['link']}></Continent>
        <Continent image={Mapa_5} className="Mapa_5" info={this.state.elements[4]['info']} href={this.state.elements[4]['link']}></Continent>
        <div className="clear"></div>
      </div>
    );
  }
}

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      elements: null,
    };
  }
  componentDidMount() {
    fetch(config.url.SITE_URL + "api.php?for_homepage=1")
      .then((response) => response.json())
      .then((data) => this.setState({ elements: data }));
  }
  render() {
    return (
      <div className="page">
        <div className="list">
          <Map></Map>
          {this.state.elements && (
            <Elements elements={this.state.elements}></Elements>
          )}
        </div>
        <div className="clear"></div>
      </div>
    );
  }
}
