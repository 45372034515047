import React, { Component } from "react";
import { config } from "./constants";

class Elements extends React.Component {
  renderElements() {
    if (this.props.elements)
      return this.props.elements.map((item) => {
        return (
          <a key={item["key"]} href={"projects/" + item["key"]}>
            <div>
              <img
                src={config.url.SITE_URL + "files/m_" + item["var5"]}
                alt={item["var0"]}
              />
            </div>
            <h2 dangerouslySetInnerHTML={{ __html: item["var0"] }} />
            <div className="second">
              <div>{item["var2"]}</div>
              <div>
                {item["var3"]}
              </div>
            </div>
          </a>
        );
      });
  }
  render() {
    return <div className="list">{this.renderElements()}</div>;
  }
}

export default class Current extends Component {
  constructor(props) {
    super(props);

    this.state = {
      elements: null,
    };
  }
  componentDidMount() {
    fetch(
      config.url.SITE_URL +
        "api.php?ojciec=1&nazwa=project"
    )
      .then((response) => response.json())
      .then((data) => this.setState({ elements: data }));
  }
  render() {
    return (
      <div className="page">
        <div className="pageHeadLine">
          <div>Projects</div>
          <div className="clear"></div>
        </div>
        <Elements elements={this.state.elements}></Elements>
        <div className="clear"></div>
      </div>
    );
  }
}
